import * as React from "react"
import { graphql } from "gatsby"
const Layout = React.lazy(() => import("../components/layout"))
const Intro2 = React.lazy(() => import('../components/sections/Intro2'))
const SolicitarUnaCita = React.lazy(() => import("../components/sections/SolicitarUnaCitaPrincipal"))

const Formulario = React.lazy(() => import('../components/sections/FormularioEmprender'))
const CProductosRecomendados = React.lazy(() => import('../components/sections/ProductosRecomendados'))
const ImagenesSegmentos = React.lazy(() => import("../components/sections/ImagenesSegmentos"))
const PromesaComercial = React.lazy(() => import("../components/sections/PromesaComercial/PromesaComercial"))


const Testimonios = React.lazy(() => import("../components/sections/Testimonios/Testimonios"))
const Testimonial2 = React.lazy(() =>
  import("../components/sections/Testimonial2")
)
const Videos = React.lazy(() => import('../components/sections/Videos'))


const ProductosVistos = React.lazy(() => import('../components/ProductosVistos/vistosRecientemente'))
const SeoEmpresa = React.lazy(() => import('../components/SEO/seoEmpresa'))
const Suscribete = React.lazy(() => import("../components/sections/Suscribete"))


const IndexPage = ({ data }) => {
  let {
    allBannerTipo4,
    landingPage,
    allBannerComentarios,
    allBannerPromesaComercial,
    SegmentosImagenes,
    ProductosRecomendados,
  } = data
  const banner = allBannerTipo4.edges.map(e => e.node)
  const PProductosRecomendados = ProductosRecomendados.edges.map(e => e.node)
  const SegmentosImagen = SegmentosImagenes.edges.map(e => e.node)
  const bannerComentarios = allBannerComentarios.edges.map(e => e.node)
  const bannerPromesaComercial = allBannerPromesaComercial.edges.map(e => e.node)
  const landingPages = landingPage.edges.map(e => e.node)
  const isSSR = typeof window === "undefined"

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Layout>
            <div className="landing">
              <SeoEmpresa
                title="Mi Tiendita megax"
                keywords={[
                  "Suministros",
                  "Limpieza",
                  "distribuidora",
                  "producto de limpieza",
                  "venta de producto de limpieza",
                  "venta de tapetes ",
                  "venta de cloro por mayor ",
                  "venta de papel de baño ",
                  "venta de trapeadores",
                ]}
                image={
                  "https://disdelsa.com/imagenes/BannerImagen-img2133-8-6-2021-73315.png"
                }
              />
              <Intro2 banner={banner} />
              {/* <SobreKimberly LandingPages={LandingPages} /> */}
              <ImagenesSegmentos SegmentosImagen={SegmentosImagen} />
              {/* <SolicitarUnaCita /> */}
              <Formulario />


              {/* <CProductosRecomendados Recomendados={PProductosRecomendados} /> */}
              {/* <Testimonial2 LandingPages={LandingPages} /> */}
              {/* <PromesaComercial bannerPromesaComercial={bannerPromesaComercial} /> */}
              <Videos />
              {/* <Testimonios bannerComentarios={bannerComentarios} /> */}
              <ProductosVistos />
              <Suscribete />

            </div>
          </Layout>
        </React.Suspense>
      )}
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allBannerTipo4: allBannersJsonJson(filter: { IdTipoBanner: { in: 4 } }) {
      edges {
        node {
          Imagen
          BannerImagenMovil
        }
      }
    }
    SegmentosImagenes: allMenuJsonJson {
      edges {
        node {
          NombreSegmento
          Imagen
          SegmentoAux
        }
      }
    }
    landingPage: allLandingPajeJsonJson{
      edges {
        node {          
          EntityID
          IdDivision          
          IdLandingPage
          IdCompania
          TipoLanding
          Titulo
          Subtitulo
          CuerpoPagina
          CuerpoPajinaMovil        
        }
      }
    }
    allBannerComentarios: allBannersJsonJson(filter: { IdTipoBanner: { in: 18 } }) {
      edges {
        node {
          Imagen          
          BannerImagenMovil   
          Titulo
          Subtitulo  
          ImagenTAG     
        }
      }
    }
    allBannerPromesaComercial: allBannersJsonJson(filter: { IdTipoBanner: { in: 19 } }) {
      edges {
        node {
          Imagen          
          BannerImagenMovil   
          Titulo
          Subtitulo  
          ImagenTAG     
        }
      }
    }
    ProductosRecomendados:allProductosRecomendadosJson(filter: {Tipo: {in: 0}}) {
      edges {
        node {
          id
          IdProducto
          IdRelacion
          Descripcion
          Imagen
          BaseUnidad
          ItemCodeAux
          BaseDefault
          BaseFardo
          Fardo
          Categoria
          IdCategoria
          Unidad
          PrecioIVA
          MultiploFardo
          MultiploUnidad
        }
      }
    }
  }
`
